.p-30{
    padding: 30px !important;
}

.p-15{
    padding: 15px !important;
}

.mb-20{
    margin-bottom: 20px;
}

.mb-10{
    margin-bottom: 10px;
}

.mb-15{
    margin-bottom: 15px;
}

.mb-40{
    margin-bottom: 40px;
}

.mt-40{
    margin-top: 40px;
}

.mt-20{
    margin-top: 20px;
}

.m-15{
    margin: 15px;
}

.mb-30{
    margin-bottom: 30px;
}

.mr-15{
    margin-right: 15px;
}

.mr-20{
    margin-right: 20px;
}

.mb-5{
    margin-bottom: 5px;
}


.mt-120{
    margin-top: 120px;
}

.mt-10{
    margin-top: 10px;
}

.mt-20{
    margin-top: 20px;
}

.mt-30{
    margin-top: 30px;
}

.mt-70{
    margin-top: 70px;
}
.mt-60{
    margin-top: 60px;
}
.mb-60{
    margin-bottom: 60px;
}
.mb-70{
    margin-bottom: 70px;
}
.mr-20{
    margin-right: 20px;
}
.ptb-70{
    padding: 70px 0;
}
.mtb-30{
    margin: 30px 0;
}

.mr-100{
    margin-right: 100px;
}
.mr-120{
    margin-right: 120px;
}

.mr-10{
    margin-right: 10px;
}

.mtb-70{
    margin: 70px 0;
}

.ptb-120{
    padding: 120px 0;
}

.ptb-100{
    padding: 100px 0;
}

.m-auto{
    margin: 0 auto;
}


@media (max-width: 1024px){
    .mt-sm-5{
        margin-top: 5px;
    }
}