.header-desktop{
    position: fixed;
    max-width: 1920px;
    width: 100%;
    height: 108px;
    display: flex;
    align-items: center;
    z-index: 99;

    .container{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 1024px){
            display: flex;
        }
    }

    nav{
        width: 100%;
        display: flex;
        align-items: center;
        

        @media (max-width: 1024px){
            display: flex;
        }

        .brand{
            margin-top: 0 !important;
            a{
                display: flex;
            }
            margin-right: 70px;
            img{
                max-width: 105px;
                height: 80px;
            }
        }

        #desktop-nav-menu{

            #desktop-main-menu{

                @media (max-width: 1024px){
                    position: absolute;
                    top: 50%;
                    left: 25px;
                    transform: translateY(-50%);
                }

                li{
                    list-style: none;
                    float: left;
                    margin-right: 50px;

                    @media (max-width: 1024px){
                        width: 100%;
                    }

                    a{
                        letter-spacing: 0.01em;
                        font-size: 20px;
                        line-height: 27px;
                        letter-spacing: 0.01em;
                        display: inline-block;
                        color: transparent;
                        font-family: $primary_font;
                        -webkit-text-stroke: 1px #ffffff;
                        font-weight: 800;
                        border-bottom: 4px solid transparent;
                        text-transform: uppercase;

                        &:hover{
                            color: #F0EA4A;
                            -webkit-text-stroke: 0px #F0EA4A;
                        }

                        @media (max-width: 1024px){
                            font-size: 56px;
                            line-height: 56px;
                            margin-bottom: 30px;
                        }

                    }
                }
            }

            @media (max-width: 1024px){
                display: none;
            }

        }
        
        .social-menu{
            margin-left: auto;
            
            a{
                margin-right: 30px;

                svg{
                    width: 20px;
                    height: 20px;
                    
                    &:hover{
                        
                        path{
                            fill: #F0EA4A;
                        }
                    }
                }
            }

            @media (max-width: 1024px){
                display: none;
                float: left;
                bottom: 20px;
                position: absolute;
            }

        }

    }
    
    .nav-btn{
        display: none;
        position: absolute;
        right: 30px;
        top: 30px;

        @media (max-width: 1024px){
            display: block;
        }
        
    }

    .nav-btn.close{

        @media (max-width: 1024px){
            display: none;
        }

    }

    @media (max-width: 1024px){
        height: auto;
        display: inherit;
        padding: 25px 0;
    }

}

.header-desktop nav #desktop-nav-menu #desktop-main-menu li.active a{
    color: #F0EA4A;
    -webkit-text-stroke: 0px #F0EA4A;
}

.header-desktop.headroom--not-top li.active a:after{
    opacity: 1;
}

.sticky-bg{
    z-index: -1;
    img{
        width: 100%;
    }
}


.sticky{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;

    .sticky-bg{
        display: block;
    }

    nav{

        .brand{
            height: 80px;
            line-height: 100px;
        }

        #desktop-nav-menu{
            min-width: 775px;

            #desktop-main-menu{

                li{

                    a{
                        line-height: 80px;
                        height: 76px;

                        &:hover{
                            color: $primary_color;
                            -webkit-text-stroke: 1px transparent;
                            border-bottom: 4px solid $primary_color;
                        }
                    }
                }
            }
        }

        .social-menu{
            line-height: 90px;
        }

    }
}


.sticky-bg,
.brand{
    opacity: 0;
}

.brand{
    transition-duration: .2s;
    transition-delay: .1s;
    width: 0%;
}

.freeze,
.headroom--not-top{
    .container{
        height: 100%;
        display: flex;
    } 
}

.headroom--not-top{
    .brand{
        width: 105px;
    }
}

.header-desktop{
    height: 110px;
    max-height: 110px;

    @media (max-width: 1024px){
        width: 100%;
        box-sizing: border-box;
        padding: 0;
        height: 80px;
    }
}

.mobile-nav-bg{
    opacity: 0;
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    img{
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}

.full-screen{
    width: 100%;
    height: 100%;
    max-height: 100%;
    .mobile-nav-bg{
        @media (max-width: 1024px){
            opacity: 1;
        }
    }    
}

.header-desktop.headroom--not-top{
  
    li {
        height: 110px;
        display: flex;
        align-items: center;
        
        a{
            position: relative;
            height: 100%;
            display: flex !important;
            align-items: center !important;

            &:after{
                content: '';
                width: 100%;
                height: 5px;
                background-color: #F0EA4A;
                position: absolute;
                bottom: -2px;
                opacity: 0;
                transition-duration: .2s;
            }

            &:hover{
                &:after{
                    opacity: 1;
                }
            }
        }

    }

    .sticky-bg,
    .brand{
        opacity: 1;
    }
}


.mobile-navigation-card{
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 999;
    transform: translateY(-1600px);
    transition: transform .2s ease-in-out;
    display: none;
    @media (max-width: 1024px){
        display: block;
    }

    .mobile-nav-bg{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        
        img{
            width: 100%;
            height: 100%;
        }
    }

    .inner-container{
        width: 100%;
    }

    .logo-and-hamburger{
        display: flex;
        justify-content: space-between;
        height: 84px;
        align-items: center;

        .brand{
            opacity: 1;
            max-width: 120px;
            margin-top: 0;
            height: max-content;
            margin-left: 30px;

            a{
                display: flex;
            }

            img{
                height: 100%;
            }
        }

        .close-nav{
            margin-right: 30px;
        }
    }

    .container{
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 9999;
        padding: 0;
    }

    .mob-nav-items{
        margin-left: 30px;
        margin-right: 30px;
        display: flex;
        align-items: center;
        height: calc(100vh - 224px);
        overflow-y: auto;
        position: relative;

        li{
            list-style: none;
            margin-bottom: 50px;

            &:last-child{
                margin-bottom: 0;
            }

            a{
                font-family: 'Manufaktur';
                text-transform: uppercase;
                font-weight: 800;
                font-size: 56px;
                line-height: 100%;
                color: transparent;
                -webkit-text-stroke-width: 1px;
                -webkit-text-stroke-color: #ffffff;

                &:hover{
                    color: #F0EA4A;
                    -webkit-text-stroke-color: #F0EA4A;
                }
            }
        }
    }

    .social-menu{
        margin-left: 30px;
        margin-right: 30px;
        display: flex;
        align-items: center;
        height: 80px;

        a{
            margin-right: 35px;

            svg{
                width: 20px;
                height: 20px;
                
                &:hover{
                    
                    path{
                        fill: #F0EA4A;
                    }
                }
            }

            &:last-child{
                margin-right: 0;
            }
        }
    }
}