.we-create{
    min-height: 300px;

    .desktop-pizza{

        @media (max-width: 990px){
            display: none;
        }
    }

    .mobile-pizza{
        display: none;

        @media (max-width: 990px){
            display: block;
        }
    }

    

}

