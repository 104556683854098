.choose-crave{
    padding-top: 113px;
    padding-bottom: 120px;

    @media (max-width: 768px){
        padding-top: 80px;
        padding-bottom: 80px;
    }

    .section-title{
        margin-bottom: 60px;

        @media (max-width: 1024px){
            margin-bottom: 50px;
            padding: 0 10px;

            h2{
                font-size: 56px;
                line-height: 56px;
            }
        }
    }

    .box-item-wrapper{
        @media (max-width: 1024px){
            margin-top: 0px;
        }
    }

    .mr-20{
        
        @media (max-width: 768px){
            margin-right: 0 !important;
        }
    }
    
    .ptb-70{
        padding: 30px 0;
    }
}

.crave-category-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 70px 0;
    margin-bottom: 10px;
    position: relative;
    margin-right: -120px;

    .crave-item{
        cursor: pointer;
        width: auto;
        text-align: center;
        padding: 0;
        margin-right: 120px;
        margin-left: 0;
        margin-bottom: 60px;
        
        
        svg{
            margin-bottom: 10px;
            opacity: 0.45;
        }

        h4{
            font-size: 24px;
            line-height: 32px;
            color: #fff;
            font-family: $primary_font;
            font-weight: 700;
            margin-top: 15px;
            text-transform: uppercase;
        }

        &:hover{

            svg{
                opacity: 1;
                path{
                    stroke: #f0ea4a;
                    opacity: 1;
                }
            }

            h4{
                color: #F0EA4A;
            }

        }
    }

    .crave-item.active {

        svg{
            opacity: 1;
            path{
                stroke: $primary_color;
            }
        }

        h4{
            color: $primary_color;
        }
    }
    
}
.choose-crave{
    position: relative;
    overflow: hidden;

    img.crave-bg1{
        height: 100%;
    }

    .choose-crave-pattern{
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
    }

    .content{
    
        p{
            color: #fff;
            margin-bottom: 40px;
        }

        a,a.download{
            font-family: $primary_font;
            font-size: 20px;
            line-height: 27px;
            text-transform: uppercase;

            svg{
                margin-right: 5px;
                height: 16px;
                width: 16px;
            }
        }
        a.download{
            color: #F0EA4A !important;

            svg{
                    
                path{
                    fill: #F0EA4A !important;
                }
            }

            &:hover{
                color: #fff !important;

                svg{
                    
                    path{
                        fill: #fff !important;
                    }
                }
            }
        }
    }

    .tab-pane{
        padding-top: 70px;
        border-top: 1px solid rgba(255, 255, 255, 0.5);

        @media (max-width: 1024px){
            padding-top: 0;
            border: none;
        }

        .tab-pane{

            .tab-pane{
                border: none;
                padding-top: 0;
            }

        }

    }
}

.crave-category-list {
    @media (max-width: 1024px){
        display: none;
    }
}

.sub-category-dropdown{
    margin-top: 10px;
}

// #home .section-title,
// #specials .section-title,
// #create .section-title,
// #wing .section-title,
// #offers .section-title,
// #extra .section-title{
//     @media (max-width: 1024px){
//         display: none;
//     }
// }

.tab-pane{
    .section-title{
        @media (max-width: 1024px){
            display: none;
        }
    }
}

.tab-pane-inner{
    .section-title{
        @media (max-width: 1024px){
            display: block;
            padding-top: 50px;
            border-top: 1px solid rgba(255, 255, 255, 0.5);
            margin-top: 60px;
            margin-left: -30px;
            margin-right: -30px;

            h2{
                margin-left: 30px;
                margin-right: 30px;
            }
        }
    } 
}

.menuContent .menu-details-item{
    @media (max-width: 1024px){
        margin-top: 50px !important;
    }
}



.choose-flavour{
    @media (max-width: 1024px){
        margin-top: 0 !important;
    }
}


#wing{
   
        .tab-pane{
            border: none;
            padding-top: 0;
        }

        .price-category .d-flex{
            flex-direction: row;

            @media (max-width: 1200px){
                flex-direction: column;
            }
        }

        .price-category-item{
            .box-content{
                max-width: 260px;
                min-height: 130px;
                margin-left: auto;
                margin-right: auto;
            }
        }
   
        .menu-details-item{
            @media (max-width: 1024px){
                margin-bottom: 50px;
            }
            .d-flex{
                @media (max-width: 1024px){
                    flex-direction: column;
                }
            }

            .price{
                @media (max-width: 1024px){
                    margin-bottom: 60px;

                    &:last-child{
                        margin-bottom: 0;
                        line-height: 1;
                    }
                }
            }
        }
}


.divider{
    position: relative;
    border: 1px solid rgba(255, 255, 255, 0.5);
    margin-bottom: 70px;
}

/* Style the tab */
.menuContent {
    margin-bottom: 70px;
}

.menuDetailsContent,.menuContent{

    .size-title{
        font-family: $primary_font;
        font-size: 24px;
        line-height: 32px;
        text-transform: uppercase;
    }

    .price-title{
        font-family: $primary_font;
        text-transform: uppercase;
        font-size: 60px;
        line-height: 60px;
    }

    .box-title,.flovour-box-title{
        font-size: 24px;
        line-height: 32px;
        font-family: $primary_font;
        text-transform: uppercase;
        margin-bottom: 15px;
    }

    .box-title.text-primary{
        &:last-child{
            margin-bottom: 0 !important;
            line-height: 1;
        }
    }

    .box-content{
        line-height: 22.4px;
        font-family: $second_font;
        margin-bottom: 15px;
    }

    .flovour-box-title{
        line-height: 40px;
    }
}

.menu-details{

    .crave-category-list{
        justify-content: center;
    }

}

.menu-details-item{

    .price{
        margin: 0px 60px;
        margin-top: 30px;

        @media (max-width: 992px){
            margin-right: 0 !important;
            margin-left: 0 !important;
            margin-bottom: 60px;
            width: 100%;
        } 
    }
}

.box-item-wrapper{
    margin-right: -20px;
    @media (max-width: 768px){
        margin-right: 0;
    }
    .box-item{
        box-sizing: border-box;
        min-height: 130px;
        h3,
        .box-content{
            margin-bottom: 15px;
        }
        p:last-child{
            margin-bottom: 0;
        }
        @media (max-width: 768px){
            margin-right: 0 !important;
        }      
    }
}

#create{
    .tab-pane-inner{
        .mr-20{
            margin: 0;
        }
    }
}

.flavour-box{
    
    h3{
        margin-bottom: 0;
        padding-bottom: 20px;
        margin-bottom: 20px !important;
        position: relative;

        &:after{
            content: '';
            position: absolute;
            bottom: 0;
            left: calc(50% - 300px);
            width: 600px;            
            height: 1px;
            background-color: rgba(255, 255, 255, 0.5);
            
            @media (max-width: 768px){
                width: 300px;
                left: calc(50% - 150px);
            }

            @media (max-width: 600px){
                width: 200px;
                left: calc(50% - 100px);
            }
        
        }
    }

    .separator{
        width: 600px;
        height: 1px;
        background: rgba(255, 255, 255, 0.5);
        margin: 20px 0;
        text-align: center;
        margin-left: auto;
        margin-right: auto;

        @media (max-width: 768px){
            width: 300px;
            left: calc(50% - 150px);
        }

        @media (max-width: 600px){
            width: 200px;
            left: calc(50% - 100px);
        }
    }
}

#categorySelect{
    display: none;
    text-align: center;
    background: transparent;
    border: 2px solid #fff;
    padding: 15px;
    font-size: 20px;
    color: #fff;
    line-height: 27px;
    font-weight: 700;
    margin: 0px auto;
    font-family: "Manufaktur";
    text-transform: uppercase;
    outline: 0;
    text-align: center;
    text-align-last: center;
    -moz-text-align-last: center;
    width: 290px;
}

select {
    text-align: center;
    background: transparent;
    border: 2px solid #fff;
    padding: 15px;
    font-size: 20px;
    color: #fff;
    line-height: 27px;
    font-weight: 700;
    margin: 55px auto 15px auto;
    display: table;
    font-family: "Manufaktur";
    text-transform: uppercase;
    outline: 0;
    text-align: center;
    text-align-last: center;
    -moz-text-align-last: center;
    width: 354px;

    @media (max-width: 360px){
        width: 90%;
    }

    option{
        background-color: black;
        color: #f0ea4a;

        &:first-child{
            pointer-events: none;
        }
    }
}

select{
    option.active,
    option.hover,
    option.focus{
        color: $primary_color;
    }
}

.price-category-item {
    max-width: 33.33%;
    padding: 5px;
    @media (max-width: 1024px){
        margin-bottom: 15px;
    }
}


.tab-selectors{
    display: none;

    @media (max-width: 1024px){
        display: block;
    }
}



@media (max-width: 1024px){
    .ending-item{
        margin-top: 60px;
    }
}

.tab-selectors{
    select.active{
        color: rgb(2, 155, 171);
        background-color: rgb(255, 255, 255);
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDE1IDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik02LjkzMjk5IDguNzcwMzJDNy4yNDIyNyA5LjA3NjU2IDcuNzU3NzMgOS4wNzY1NiA4LjA2NzAxIDguNzcwMzJMMTQuNzY4IDIuMTY5MTlDMTUuMDc3MyAxLjgyODkyIDE1LjA3NzMgMS4zMTg1MyAxNC43NjggMS4wMTIyOUwxMy45Nzc3IDAuMjI5Njc5QzEzLjY2ODQgLTAuMDc2NTU5NSAxMy4xNTI5IC0wLjA3NjU1OTUgMTIuODA5MyAwLjIyOTY3OUw3LjUxNzE4IDUuNDY5NzVMMi4xOTA3MiAwLjIyOTY3OUMxLjg0NzA4IC0wLjA3NjU1OTUgMS4zMzE2MiAtMC4wNzY1NTk1IDEuMDIyMzQgMC4yMjk2NzlMMC4yMzE5NTkgMS4wMTIyOUMtMC4wNzczMTk2IDEuMzE4NTMgLTAuMDc3MzE5NiAxLjgyODkyIDAuMjMxOTU5IDIuMTY5MTlMNi45MzI5OSA4Ljc3MDMyWiIgZmlsbD0iIzAyOUJBQiIvPgo8L3N2Zz4K") !important;
    }
}


.price-category{
    @media (max-width: 1024px){
        margin-top: 0;
    }
    .d-flex{
        flex-direction: column;

        .price-category-item{
            width: 100%;
            max-width: 100%;
            padding: 0 !important;
        }
    }
}


#offers,
#extra{

    @media (max-width: 1024px){
        margin-top: 50px;
    }

    .section-title{
        display: block;
    }

    .mtb-70{
        @media (max-width: 1024px){
            margin: 50px auto;
        }
    }
}

.menuContent .size-title{
    margin-bottom: 15px;
}

.menuContent .size-small-title{
    margin-bottom: 20px;
}

.offers-box,
.extras-box{
    display: flex;
    justify-content: space-between;
    @media (max-width: 1024px){
        flex-direction: column;
    }
}

.offers-title-desc,
.extras-title-desc{
    margin-right: 20px;
    @media (max-width: 1024px){
        margin-right: 0;
        margin-bottom: 15px;
    }
}



.price-category-item{
    .box-content{
        min-height: auto !important;
    }
}

#create .one-column .box-item{
    margin-bottom: 20px;
}