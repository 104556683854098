.d-flex{
    display: flex;

} 

.flex-wrap{
    display: flex;
    flex-wrap: wrap;
}

.justify-content-center{
    justify-content: center;
}

.d-inline-block{
    display: inline-block;
}

.d-block{
    display: block;
}

.d-inline-block{
    display: inline-block;
}

.two-column{
    display: grid;
    grid-template: auto / 50% 50%;
    grid-gap: 0px;

    @media (max-width: 768px){
        display: initial;
    }
}

.three-column {
    display: grid;
    grid-template: auto / 33.33% 33.33% 33.33%;
    grid-gap: 25px;
}

.col-2{
    width: 50%;

    @media (max-width: 600px){
        width: 100%;
    }
}

.col-4 {
    max-width: 33.333333%;
}

.col-3 {
    max-width: 25%;
}

.first-column-extend {
    display: grid;
    grid-template: auto / 60% 40%;
    grid-gap: 0px;
}

.one-column{
    column-count: 1;
}

.full-width{
    width: 100%;
}
.half-width{
    width: 50%;
}

.full-screen{
    height: 100vh !important;
}



@media (max-width: 1024px){

    .content-sm-center{
        justify-content: center;
    }
    
}