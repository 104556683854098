.main-slider{
    position: relative;
    width: 100%;
    max-height: 100vh;
    min-height: 100vh;
    @media (max-width: 1024px){
        max-height: calc(100vh - 60px);
    }
    margin-bottom: 0px !important;

    .slider-item{
        position: relative;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;

        .slider-thumb{
            width: 100%;
            height: 100%;
            -o-object-fit: cover;
            position: absolute;
            top: 0;
            left: 0;
            object-fit: cover;
        }

        video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .slider-content{
            max-width: 705px;
            position: relative;
            
            @media (max-width: 1024px){
                width: 100%;
                padding: 20px;
                text-align: center;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                img{
                    max-width: 340px;
                    margin: 0 auto;
                }
            }

            @media (max-width: 600px){
                img{
                    height: auto;
                }
            }

            h2{
                color: #fff;
                margin-top: 45px;
                font-size: 64px;
                color: transparent;
                font-family: $primary_font;
                -webkit-text-stroke: 1px #ffffff;
                font-weight: 800;
                text-transform: uppercase;

                strong{
                    color: $primary_color;
                    margin: 0 10px !important;
                    -webkit-text-stroke: 0px #ffffff;
                }

                @media (max-width: 1024px){
                    margin-top: 10px;
                    font-size: 30px;
                }
                @media (max-width: 600px){
                    margin-top: 20px;
                }
            }
        }
    }

    .slick-list.draggable{

        @media (max-width: 1024px){
            height: 100vh;

            .slick-track{
                height: 100vh;
            }
        }  
    }
}

.main-slider.slick-vertical .slick-slide {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: 0;
    border: 0px;
}

.main-slider,.mobile.promo-section{

    .slick-dots {
        bottom: 55%;
        display: block;
        width: 100%;
        padding: 0;
        margin: 0;
        list-style: none;
        text-align: center;
        right: 60px;
        width: auto;
        transform: rotate(90deg);

        @media (max-width: 1024px){
            bottom: 30px;
            right: 50%;
            width: auto;
            transform: none;
            transform: translateX(50%);
        }

        li {
            button{
                width: 10px;
                height: 10px;
                max-width: 10px;
                max-height: 10px;
                box-sizing: border-box;
                background: #fff;
                border: 2px solid #fff;
            }
        }

        li.slick-active{

            button{
                background: transparent;
                border: 2px solid #F0EA4A;
                box-sizing: border-box;
                width: 10px;
                height: 10px;
            }
        }
    }

    .slick-dots li button:before{
        display: none;
    }
}

.mobile.promo-section{

    .slick-dots {

        @media (max-width: 990px){
            bottom: -34px;
            right: 50%;
            width: auto;
            transform: none;
            transform: translateX(50%);
        }
    }
}


.slick-dots li {

    width: 10px;
    height: 10px;
    margin: 0 12.5px;

    button{
        width: 10px !important;
        height: 10px !important;
        padding: 0;
    }

}