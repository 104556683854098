

@font-face {
    font-family: 'Config Rounded';
    src: local("Config Rounded"), url("../fonts/Config-Rounded-Regular.otf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap; 
}

@font-face {
    font-family: 'Manufaktur';
    src: local("Manufaktur"), url("../fonts/Manufaktur-Black.otf") format("truetype");
    font-weight: 800;
    font-style: normal;
    font-display: swap; 
    text-transform: uppercase;
}

$primary_font:'Manufaktur';
$second_font:'Config Rounded';

body{
    overflow-x: hidden;
    font-family: 'Config Rounded';
    font-size: 14px;
}

h1,h2,h3,h4,h5,h6{
    font-family: $primary_font;
    text-transform: uppercase;
}

.text-center{
    text-align: center;
}

.text-primary{
    color: $primary_color !important;
}

.text-3{
    color: $third_color !important;
}

.text-4{
    color: #171717;
}

.text-uppercase{
    text-transform: uppercase;
}

.font-weight-bold{
    font-weight: 700;
}

.box-title{
    font-size: 24px;
    font-family: $primary_font;
    line-height: 32px;
    text-transform: uppercase;
}

.l-spacing{
    letter-spacing: 2px;
}

.text-shadow{
    text-shadow: 3px 3px #000000;
}

.line-break-word{
    white-space: pre-wrap;
}