.location-content{
    max-width: 350px;
    address{
        font-style: initial;
        margin-bottom: 35px;
    }

    @media (max-width: 992px){
        max-width: 100%;
        margin-right: auto;
    }
    
}
#map {
    height: 100%;

    @media (max-width: 992px){
        min-height: 500px;
    }
}

.location.d-flex{

    @media (max-width: 992px){
        flex-direction: column-reverse;

        .col-2{
            width: 100%;
        }
    }
}


.location{
    .text-4 *{
        color: #171717;
    }

    .text-4 a{
        text-decoration: underline;
        &:first-child{
            margin-right: 10px;
        }
    }

    .section-title{
        h2{
            -webkit-text-stroke: 2px #F15F60;
        }
        strong{
            margin-right: -6px;
        }
        em{
            letter-spacing: 0.1em;
        }
    }
}