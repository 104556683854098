.btn-group{
    display: flex;
}
.btn-transparent{
    padding: 0;
    min-width: 156px;
    height: 60px;
    font-family: $primary_font;
    font-size: 20px;
    border: 2px solid;
    line-height: 8px;
    min-height: 16px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;

    svg{
        margin-right: 10px;
    }

    @media (max-width: 1024px){
        padding: 15px 20px;
        line-height: 15px;
        min-height: 16px;
        vertical-align: middle;
    }

    @media (max-width: 560px){
        padding: 15px 10px;
        line-height: 27px;
        min-height: 16px;
        vertical-align: middle;
        min-width: 130px;
        text-align: center;
        font-size: 17px;
    }

}

button{
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
}

.btn-group{
    display: flex;
}