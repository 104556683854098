img.service-thumb {
    position: absolute;
    z-index: 99;
    bottom: 10px;
    left: 234px;
    height: 312px;
}


.desktop.promo-section{ 
    display: flex !important;
    margin-top: 160px;
    margin-right: -50px;

    .main-card{
        margin-right: 50px !important;
        width: 100%;
    }

    .main-card.p-15{
        padding: 12px !important;
    }

    .box-item.border-white,.box-item-inner.border-white{
        border: 2px solid #fff;
    }
   
    .box-item-inner{
        padding: 37px 25px 37px 25px;

        .card-icon-holder{
            height: 87px;
            display: flex;
            align-items: center;

            img{
                width: 100%;
            }
        }
    }

    .box-item{
        margin: 0;
    }

    .box-item-inner{
        height: 100%;
        box-sizing: border-box;
    }

    @media (max-width: 1024px){
        flex-wrap: wrap;
        justify-content: center;

        .col-4 {
            max-width: 43.333333%;
        }
    }

    @media (max-width: 992px){
        display: none !important;
    }
}

.mobile.promo-section{
    display: none;
    margin-left: -15px;
    margin-right: -15px;

    .box-item.border-white,.box-item-inner.border-white{
        border: 2px solid #fff;
        outline: none;
    }

    @media (max-width: 992px){
        display: block;
    }

    .slick-slide{

        .box-item-inner{
            padding: 50px 33px 37px 33px;

            .mt-40{
                margin-top: 20px;
            }

            .mb-30{
                margin-bottom: 30px;
            }

            .content {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
            }
        }

        img{
            display: initial;
        }
    }


}

.all-services-holder{

    .section-image-holder{
        display: flex;

        @media (max-width: 992px){
            flex-direction: column;
        }

        .box-item{
            position: relative;
            margin-right: 0;
            .service-img-1{
                max-width: 412px;
                @media (max-width: 460px){
                    max-width: 250px;                
                }
                img{
                    width: 100%;
                    height: auto;
                    object-fit: contain;
                    filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.35));
                }
            }
            .service-img-2{
                position: absolute;
                bottom: -75px;
                right: 0;
                max-width: 309px;
                @media (max-width: 460px){
                    max-width: 174px;
                    bottom: -50px;
                }
                img{
                    width: 100%;
                    height: auto;
                    object-fit: contain;
                    filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.35));
                }
            }

            &:first-child{
                max-width: calc(100% -  460px);
                width: 100%;
                margin-right: 162px;

                @media (max-width: 1200px){
                    margin-right: 50px;
                    max-width: calc(100% -  360px);
                }

                @media (max-width: 1024px){
                    margin-left: auto;
                    margin-right: auto;
                }

                @media (max-width: 992px){
                    max-width: calc(100% - 160px);
                    margin-left: auto;
                    margin-right: auto;
                }

                @media (max-width: 768px){
                    max-width: 100%;
                }
            }

            &:last-child{
                max-width: 460px;
                @media (max-width: 992px){
                    max-width: 100%;
                }
                width: 100%;
            }
        }
        @media (max-width: 1024px){
            display: inherit;
        }
    }

    .btn-group{
        a:last-child{
            min-width: 209px;
            margin-right: 0;

            @media (max-width: 600px){
                min-width: auto;
                margin-right: 0;
                width: 100%;
            }
        }

        a{
            max-height: 60px;
            box-sizing: border-box;
            
            &:hover{
                background-color: #f0ea4a;
                border: 2px solid #f0ea4a;
                color: #F15F60 !important;

                svg{
                    
                    path{
                        fill: #F15F60;
                    }
                }
            }
        }
    }

    .section-title{
        margin-bottom: 36px;
        @media (max-width: 1200px){
            margin-top: 107px;
        }
        em{
            letter-spacing: 0.1em;
        }
        .font-weight-bold{
            margin-bottom: 16px;
        }
    }
}