$primary_color:#F0EA4A;
$third_color:#F15F60;

*{
    margin: 0;
    padding: 0;
    transition: .2s all;
}

@import "./typography.scss";

.bg-primary{
    background-color: $primary_color !important;
}

.bg-light{
    background-color: rgba(255, 255, 255, 0.5);
}

@import "./util.scss";
@import "./grid.scss";
@import "./button.scss";


html{
    max-width: 1920px;
    margin: 0 auto;
    overflow-x: hidden;
    width: 100%;
}

body{
    opacity: 0;
}

.slick-slider{
    transition: none;
    *{
        transition: none;
    }
}

html.freeze{
    overflow-y: hidden;
}

img.crave-bg1,img.crave-bg2{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.crave-bg1,.crave-bg2{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img{
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}

.container{
    margin: auto;

    // Small devices (landscape phones, 576px and up)
    @media (min-width: 576px) { max-width: 100%; }

    // Medium devices (tablets, 768px and up)
    @media (min-width: 768px) { max-width: 720px; }

    // Large devices (desktops, 992px and up)
    @media (min-width: 992px) { max-width: 960px; }

    // Extra large devices (large desktops, 1200px and up)
    @media (min-width: 1200px) { max-width: 1140px; }

    @media (max-width: 768px){
        padding-left: 40px; 
        padding-right: 40px;
    }
    @media (max-width: 600px){
        padding-left: 30px; 
        padding-right: 30px;
    }

}

img{
    max-width: 100%;
}

.top30px{
    top: 30px;
}
a{
    text-decoration: none;
}

p{
    line-height: 22px;
    font-family: $second_font;
}

.section-padding{
    padding: 120px 0;
    @media (max-width: 768px){
        padding: 80px 0;
    }
}
.text-white{
    color: #fff !important;
}
.pt-relative{
    position: relative;
}
.float-left,.left{
    float: left;
}
.float-none{
    float: none;
}
.border-white{
    border: 1px solid #fff;
}
.border-top{
    border-top: 1px solid $primary_color;
}
.section-title{
    position: relative;
    h2{
        font-size: 60px;
        line-height: 60px;
        font-weight: 700;
        color: transparent;
        -webkit-text-stroke: 2px #ffffff;
        font-family: $primary_font;
        text-transform: uppercase;
        strong{
            color: $primary_color;
            -webkit-text-stroke: 0px #ffffff;
        }
    }
}

.content{
    font-family: $second_font;
    line-height: 22px;
}

.box-radius{
    border-radius: 10px;
}

.box-radius-30{
    border-radius: 30px;
}

.box-radius-20{
    border-radius: 20px;
}

.center{
    display: flex;
    align-items: center;
    justify-content: center;
}


.question-content{
    .btn-transparent{
        min-width: 200px;
    }
}


@keyframes appearslow {
    from {opacity: 0;}
    to {opacity: 1;}
  }

.tab-pane{
    animation-name: appearslow;
    animation-duration: .4s;
}

.nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.nav-tabs .nav-item {
    margin-bottom: -1px;
}

.nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
}

.nav-link {
    display: block;
    padding: .5rem 1rem;
}

.tab-content>.tab-pane {
    display: none;
}

.tab-content>.active {
    display: block;
}


.simpleParallax{
    height: 100%;
}


.question-content{
    a{
        &:hover{
            background-color: #f0ea4a;
            border: 2px solid #f0ea4a;
            color: #f15f60!important;
        }
    }
}


select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDE1IDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik02LjkzMjk5IDguNzcwMzJDNy4yNDIyNyA5LjA3NjU2IDcuNzU3NzMgOS4wNzY1NiA4LjA2NzAxIDguNzcwMzJMMTQuNzY4IDIuMTY5MTlDMTUuMDc3MyAxLjgyODkyIDE1LjA3NzMgMS4zMTg1MyAxNC43NjggMS4wMTIyOUwxMy45Nzc3IDAuMjI5Njc5QzEzLjY2ODQgLTAuMDc2NTU5NSAxMy4xNTI5IC0wLjA3NjU1OTUgMTIuODA5MyAwLjIyOTY3OUw3LjUxNzE4IDUuNDY5NzVMMi4xOTA3MiAwLjIyOTY3OUMxLjg0NzA4IC0wLjA3NjU1OTUgMS4zMzE2MiAtMC4wNzY1NTk1IDEuMDIyMzQgMC4yMjk2NzlMMC4yMzE5NTkgMS4wMTIyOUMtMC4wNzczMTk2IDEuMzE4NTMgLTAuMDc3MzE5NiAxLjgyODkyIDAuMjMxOTU5IDIuMTY5MTlMNi45MzI5OSA4Ljc3MDMyWiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==") !important;
    background-size: 15px 9px !important;
    background-repeat: no-repeat !important;
    background-position-y: center !important;
    background-position-x: calc(100% - 15px) !important;
}


.question-content{
    a{
        max-height: 60px;
        box-sizing: border-box;
    }
}

.modal-container{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}


.modal{
    position: fixed;
    width: 100%;
    height: 100%;
    max-width: 100%;


    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 9999999;
    transform: translateY(-150%);

    .overlay{
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        background-image: none !important;
    }

    h2{
        text-align: center;
        font-family: 'Manufaktur';
        text-transform: uppercase;
        font-weight: 800;
        font-size: 60px;
        line-height: 100%;
        -webkit-text-stroke: 2px #fff;
        margin:60px 0;
        color: transparent;

        @media (max-width: 1024px){
            margin: 35px 0;
            font-size: 50px;
        }
    }

    #modal-close{
        width: auto;
        margin-left: 25px;
        margin-top: 25px;
        margin-bottom: 0;
        position: absolute;
        svg{
            cursor: pointer;
        }
    }

    .container{
        width: 100%;
        height: 100%;
        padding: 0 30px;
        max-width: 100%;
        margin-left: auto;
        box-sizing: border-box;
        margin-right: auto;
        overflow-y: auto;
    }

    .faq{
        width: 100%;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto; 
    }

    .faq-holder{
        width: calc(100% - 120px);
        margin-left: 60px;

        @media (max-width: 1024px){
            width: calc(100% - 60px);
            margin-left: 30px; 
        }
    }

    .container{
        width: 1560px;
        max-height: calc(100vh - 80px);
        overflow-y: scroll;
        top: 30px !important;
        position: absolute;
        height: 700px;
        padding: 0;
        overflow-x: hidden;

        @media (max-width: 1366px){
            width: calc(100% - 120px);
        }

        @media (max-width: 1024px){
            width: calc(100% - 60px);
        }
        @media (max-width: 400px){
            width: calc(100% - 30px);
            top: 15px !important;
        }

        &::-webkit-scrollbar
        {
            width: 8px;
            background-color: #CFCFCF;
        }
        
        &::-webkit-scrollbar-thumb
        {
            background-color: #F15F60;
        }
    
    }

    .menu-accordion{
        font-family: 'Manufaktur';
        text-transform: uppercase;
        font-weight: 800;
        font-size: 24px;
        line-height: 32px;
        display: flex;
        align-items: center;
        color: #F15F60;

        @media (max-width: 600px){
            font-size: 22px;
            line-height: 28px;
        }
    }
}



//accordion

.menu-accordion {
    background-color: #F15F60;
    color: #ffffff !important;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    transition: 0.4s;

    
  }

.panel a{
    color: #f15f60 !important;
}
.panel a:hover{
    color: #F0EA4A !important;
}
  
  .menu-accordion.active {
    background-color: #F0EA4A;
    color: #F15F60 !important;

  }


  


  .menu-accordion.active + .panel{
      padding-top: 30px;
      padding-bottom: 30px;
      border: 3px solid #F0EA4A;
  }
  
  .menu-accordion:after {
    content: url('../img/accor-down-arrow.svg');
    color: #777;
    font-weight: bold;
    float: right;
    margin-left: auto;
    padding-left: 10px;
    
  }
  
  .menu-accordion.active:after {
    content: url('../img/accor-up-arrow.svg');
  }
  
  .panel {
    font-family: Config Rounded;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;
    max-height: 0;
    padding: 0px 30px;
    color: #FFFFFF;
    overflow: hidden;
    transition: all 0.2s ease-out;
    margin-bottom: 20px;
    border: 3px solid transparent;
  }
  