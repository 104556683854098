.gallery-thumb{
    
    img{
        max-width: 100%;
    }
}

.mobile.gallery{
    margin-left: -30px;
    margin-right: -30px;

    .slider{

        div.slick-slide{
            opacity: .5;
            
            .gallery-thumb{
                padding: 10px;
                img{
                    width: 100%;
                }
            }
        }

        div.slick-slide.slick-current.slick-active.slick-center{
            opacity: 1;
        }
    }
}

.sb_instagram_header.sbi_no_avatar {
    display: none;
}

.carve-lnd{
    margin-top: 54px;
    em{
        letter-spacing: 0.1em;
        font-family: 'Config Rounded';
        font-weight: 700;
    }
    @media (max-width: 1024px){
        margin-top: 50px;
    }
}


#sb_instagram.sbi_col_4 #sbi_images .sbi_item,
#sbi_images{
    padding: 0 !important;
}

#sb_instagram.sbi_col_4 #sbi_images .sbi_item{
    margin-right: 35px !important;
    margin-bottom: 35px !important;
}

#sb_instagram.sbi_col_4 #sbi_images .sbi_item{
    width: calc(25% - 35px);
}

#sb_instagram #sbi_images{
    width: calc(100% + 35px);
}

#sb_instagram #sbi_load .sbi_load_btn {
    position: relative;
    display: none;
  }

  .svg-inline--fa.fa-play.fa-w-14.sbi_playbtn path{
    opacity: 0;
}

#sb_instagram .sbi_photo img {
    width: 100%;
    height: auto;

    @media (max-width: 1024px){
        object-fit: cover;
        height: 550px;
    }

    @media (max-width: 600px){
        object-fit: cover;
        height: 300px;
    }
}

#sb_instagram .sbi_photo {
    display: block;
    text-decoration: none;
    cursor: initial;
    opacity: 1 !important;

    @media (max-width: 1024px){
        background-size: cover!important;
        background-repeat: no-repeat;
        background-image: initial!important;
        height: auto !important;
        object-fit: cover;
        padding: 15px;
    }
}


@media (max-width: 600px){
    #sb_instagram.sbi_width_resp {
        width: 100%!important;
        margin-left: -20px;
    }
}	
