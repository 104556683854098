#wrapper-footer{
    position: relative;

    img.footer-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .footer-top-content {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 80px;
        padding-top: 50px;

        @media (max-width: 990px){
            display: inherit;
            text-align: center;
            margin-bottom: 60px;
        }

        .social-menu{
            a{
                margin-right: 35px;

                svg{
                    width: 20px;
                    height: 20px;
                    
                    &:hover{
                        
                        path{
                            fill: #F0EA4A;
                        }
                    }
                }
            }
        }

        .content{
            color: #f15f60;
            font-size: 16px;
            font-style: italic;

            @media (max-width: 990px){
                margin: 20px 0 35px 0;
            }
        }

        .hmc-logo{

            img{
                width: 70px;
                height: 70px;
                margin-left: 35px;

                @media (max-width: 990px){
                    margin-left: 0;
                }
            }
        }

    }

    .footer-bottom-content{

        .footer-logo{
            margin-bottom: 30px;

            @media (max-width: 990px){
                text-align: center;
            }

            a{

                img{
                    max-width: 105px;
                    height: auto;
                }
            }
        }

        .copyright-content {
            display: flex;
            justify-content: space-between;
            color: #fff;
            font-size: 14px;
            line-height: 22px;
            padding-bottom: 50px;

            @media (max-width: 990px){
                display: inherit;
                text-align: center;
            }

            a{
                text-decoration: underline;
                color: #fff;
            }

            div{

                @media (max-width: 990px){
                    margin-bottom: 40px;
                }

                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
    }


}


.footer-top-content{
    .social-menu{

        @media (max-width: 992px){

            a:last-child{
                margin-right: 0 !important;
            }

        }
        
    }
    .content{
        font-size: 14px;
        font-weight: 700;
    }
}


