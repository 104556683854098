.desktop{
    display: block;
}

.mobile{
    display: none;
}

@media (max-width: 1024px){

    .desktop.promo-section{
        display: none !important;
    }
    .mobile.promo-section .slick-dots{
        margin-bottom: -70px;
    }

    .desktop{
        display: none !important;
    }

    .mobile{
        display: block !important;
    }

}

@media (max-width: 992px){
    .mobile.promo-section .slick-dots{
        margin-bottom: -34px;
    }
}

@media (max-width: 600px){

    .flex-sm-wrap{
        flex-wrap: wrap;
    }

    .d-sm-inherit{
        display: inherit;
    }
}



